    
    //  -------------------------------------------------
    //  UI | Interface styles - Styles to re-use
    //     | Components and collections of elements
    //     | Ex : Buttons, lists, forms elements, boxes
    //  -------------------------------------------------
		
	// Type

	// List of custom sizes

		// The following sizes are out of styleguide
		

			$FontSizes:(
				// >md | <md >xs | <xs
	 	   		(   14,     14,    14),
	 	   		(   16,     16,    16),
	 	   		(   18,     18,    18),
	 	   		(   20,     20,    18),
	 	   		(   24,     20,    20),
	 	   		(   34,     24,    20),
	 	   		(   38,     34,    32),
	 	   		(   56,     38,    34),
	 	   		(   200,    144,   64),
	 	   	);

	 	   	// Generating size classes to deal with weird design choices
            @each $FontSize in $FontSizes{

            	/* #{nth($FontSize,1)} */
            	$value1 : #{nth($FontSize,1)/$fontbase};
            	$value2 : #{nth($FontSize,2)/$fontbase};
            	$value3 : #{nth($FontSize,3)/$fontbase};

               	.fs-#{nth($FontSize,1)}{
					font-size:#{$value1}rem;
					@include media("<md") {
						font-size:#{$value2}rem;
					} 
					@include media("<sm") {
						font-size:#{$value3}rem;
					} 
				} 

            } // FontSize in Fontsizes

			
			 
	// Headings
		
		h1, h2, h3, h4, h5, h6{
			margin-top:0;
			// line-height:1em;
			// margin-bottom:0.7em;
			small{
				display:block;
				color:currentColor;
			}
		}

	// Paragraphe
    
	p{
		// line-height:1.5;
		// margin-bottom:1.25em; 
	}

	.LastP-NoMargin{
		p:last-of-type{
			margin-bottom:0!important;
		}
	} // LastP-NoMargin

	// Link

	a{
		display:inline-block;
		color:currentColor;
		
		&:focus, &:active, &:hover{
			text-decoration: none;
			color:currentColor;
		}
		&.fc-blue{
			transition:all .15s;
			&:hover{
				border-color:$blueLight!important;
				color:$blueLight!important;
			}
		}
	} // a

	.Btn{
		border:2px solid currentColor;
		display:inline-block;
		padding:0.7em 2em;
		background:none!important;
		color:currentColor;
		font-weight:$fw-black;
		text-transform:uppercase;
		&.Rounded{
			border-radius:100px;
		}
	}

	hr{
		border:none;
		display:block;
		width:100%;
		margin:0 0 1.3rem;
		padding:0;
		background-color:currentColor;
	} // hr  

	// FORM

	.FormElement{
		display:inline-flex;
		position:relative;
		&:not(.Textarea):not(.File):not(.Checkbox):not(.Radio){
			padding:0.9em 1.3em;
		}
		&.bg-blueDark{
			input{
				@include placeholderColor($blueLight);
				&:focus{
					@include placeholderColor($white);
				}
			}
		}
		input{
			background:none; 
			border:none; 
			font-weight:$fw-semibold; 
		} // Input
		&.Textarea{
			textarea{
				padding:0.9em 1.3em;
				width:100%;
				display:block;
				border:none;
				color:$blueLight;
				background:none;
				min-height:199px;
				&:focus{
					color:$white;
				}
			} 
		} // Formelement textarea
		&.File{
			[type=text],[type=file]{
				width:100%;
				padding:0.9em 0em;
				@include placeholderColor($blueLight);
			}
			[type=file]{
				position:absolute;
				top:0;
				left:0;
				opacity:0;
				z-index:1;
			}
		} // File
		&.Checkbox{
			position:relative;
			.Icon{
				opacity:0;
				position:absolute;
				top:50%;
				transform:translateY(-50%) scale(3);
				transform-origin:center center;
				left:0.25em;
				transition:all .20s;
			} // i
			label{
				position:relative;
				margin:0;
				padding-left:2.4em;
				&:before{
				    position:absolute;
				    content:"";
				    top:50%;
				    transform:translateY(-50%);
				    left:0;
				    width:1.5em;
				    height:1.5em; 
				    background:$blueDark;
				}
			} // Label
			[type=checkbox]{
				display:none;
				&:checked{
					& ~ .Icon{
						opacity:1;
						transform:translateY(-50%) scale(1);
					}
				}
				
			} // Checkbox
			
		} // Checkbox

	}


	// Background img in containers

		[data-cover]{
			background-size:cover;
			background-position:center center;
		}

		.ThumbWrap{
			&.BG{ 
				position:absolute;
				z-index:-1;
				top:50%;
	            left:50%;
	            transform:translate(-50%, -50%);
				width:100%;
				height:100%;
				background-repeat:no-repeat;
			}
			img{
				max-width:100%;
				width:100%;
			}
		} // ThumbWrap
		[class*="col"]{
			// min-height:300px;
		}

	
	// If the list has a class, it's not default
	// Let's kill all those ugly styles
		
		@mixin ulReset(){
			list-style: none;
			padding:0;
			margin:0;
		}

		ul[class]{
			@include ulReset();
		} // UL with class
	 
	 	// Inline List

	 	.List_Inline{
			> *{
				display:inline-flex;
			}
	 	}

	// If the list does not have a class,
	// Let's redefine those ugly styles
	
		ul:not([class]){

			//

		} // When no class on UL

	i.Icon{
		line-height:1;
		height:auto;
		svg{

			width:1em;
			height:auto!important;
			*{
				fill:currentColor;
			}
		}
	}

	.Icon{ 
		
		display:inline-flex;
		vertical-align: middle;
		align-items:center;
		flex-direction:row;
		justify-content:center;
		font-style:normal; 

		span, svg{
			height:1em;
			min-width:1em;
			width:auto;
			display:inline-flex;
		} 
		
		&:not(.Icon-Reverse){
			span{
				margin-right:0.4em;
			}
		}
		&.Icon-Reverse{
			flex-direction:row-reverse;
			span{
				margin-left:0.4em;
			}
		}
		span{
			svg{
				margin:0!important;
			}
		}
	} // icon
 
	dl{ 
		display:flex;
		flex-wrap:wrap; 
		dt, dd{
			display:inline-block;
		}
		dt{
			
		}
		dd{ 
			flex-grow:1;
			// width:100%;
		} 
	}
