 
	// - Variables

		
		// Colors

			$black :        #000000;
			$white :        #ffffff;

			$blue : 		#0050BE;
			$blueLight : 	#4BB1FF;
			$blueDark : 	#003A8A;

			$yellow: 		#FFE45D;
			$gold: 			#FCAF17;

			$green:			#009444;
			$greenLight:	#BFD730;

			$gray :		    #E6E6E6;
			$gray2 : 		#CCCCCC;
			$gray3 : 		#666666;


			// Dominant color
			$activeColor : $blue; 

			// List of usable colors 

			$ColorList:(  
				// Color. // Hexa
				'black':	    $black,
				'white':	    $white, 
				'blue': 		$blue,
				'blueLight': 	$blueLight,
				'blueDark': 	$blueDark,
				'yellow': 		$yellow,
				'gold': 		$gold,
				'green': 		$green,
				'greenLight': 	$greenLight,
				'gray': 	    $gray,
				'gray2': 	    $gray2,
				'gray3': 	    $gray3,
			); // $ColorList

			@mixin placeholderColor($param){
				&::-webkit-input-placeholder { color:$param; }
				&:-moz-placeholder { color:$param;}
				&::-moz-placeholder { color:$param; }
				&:-ms-input-placeholder { color:$param; }
				&::-ms-input-placeholder { color:$param; }
				&:placeholder-shown { color:$param;}
			}
 		  
 		
		// Typography

			// Font base - Without unit to be calculated in mixins
			$fontbase : 16; 

			// Font Sizing
			@function rem($px){
				// rem = $px / 16
				$a : $px; 
				$rem : $px/$fontbase;
				@return #{$rem}rem; 
			}
			@function em($px){
				// rem = $px / 16
				$rem : $px/$fontbase;
				@return #{$rem}em;
			} 

		$f-sans : 'Muli', Arial, Helvetica, sans-serif!important; 
		//$f-condensed : 'Roboto Condensed', Arial, Helvetica, sans-serif!important;   

		.f-sans{
			font-family: $f-sans;
		} 

		$f-main : $f-sans;

			// Weights  
  

			$fw-regular:400;
			.fw-regular{
				font-weight:$fw-regular;
				font-family:$f-sans;
			} 

			$fw-semibold:600;
			.fw-semibold{
				font-weight:$fw-semibold;
				font-family:$f-sans;
			} 

			$fw-bold:700;
			.fw-bold{
				font-weight:$fw-bold;
				font-family:$f-sans;
			}  

			$fw-black:900;
			.fw-black{
				font-weight:$fw-black;
				font-family:$f-sans;
			}

			
 
