// ----------------------------------------
// ResponsiveWitness
@import '../../stylesheets/prod/vars';
[class*="ResponsiveWitness"]{
  
	&[class*="__active"]{

		// shows screen size
		@mixin ResponsiveWitness($region){
	      	position:relative; 
	        &:before{ 
	            position:fixed;
	            content:$region;
	            top:0;
	            left:0;
	            background:black;  
	            color:white;
	            text-align:center;
	            min-width:40px;
	            font-weight:500;
	            z-index:1000000;
	            opacity:0.5;
	            padding:10px 7px;
	        } 
	    }  
		
		@each $breakpoint in $breakpoints{
			@media #{nth($breakpoint, 3)}{
				@include ResponsiveWitness('#{nth($breakpoint, 1)}');
			}
		}

	} // active END

}