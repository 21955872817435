    
    //  ---------------------------------------------------------
    //  Global | Layout styles  
    //         | Ex : containers, section, headers, footer, menu
    //  ---------------------------------------------------------

    // Setup

    html { font-size:#{$fontbase}px; }


    body{
        font-family:$f-main;
        font-weight:normal;  
        color:$black;
        width:100%;
        height:100%;
        font-size:1rem;
        *, *:focus{
            outline:none!important;
            // Better Font Rendering
            -webkit-font-smoothing: antialiased!important;
            -moz-osx-font-smoothing: grayscale!important;
        }
        svg{
            max-width:100%;
            height:auto;
        }
    } // body 

    // Helpers

    .container{
        max-width:1170px;
    }

    // Display

    .d-block{
        display:block;
    }
    .d-inlineblock{
        display:inline-block;
    }

    .hidden-desktop{
        @include media(">sm") {
           display:none;
        }
    }
    .hidden-mobile{
        @include media("<md") {
           display:none;
        }
    }

    // Alignment

        .NoFloat{
            float:none;
        }

       // @include media(">sm"){ 

            .Flex_FlexEnd{
                align-self:flex-end;
            }
            .FlexRow{ 
                display:flex;
                flex-direction:row;
            }
            .AlignItems_FlexEnd{
                align-items:flex-end;
            }
            .Flex_SpaceBetween{
                display: flex;
                justify-content:space-between;
            }
 
        //   }

        .align-left{
            text-align:left;
        }
        .align-right{
            text-align:right;
        }
        .align-center{
            text-align:center;
        }
        .align-left-MOB{
            @include media("<md") {
                text-align:left!important;
            }
                
        }
        .fd-underline{
            text-decoration:underline;
        }
        .fs-normal{
            font-style:normal;
        }
        .NoMargin{
            margin:0!important;
        }
        .NoMargin-X{
            margin-left:0!important;
            margin-right:0!important;
        }
        .NoPadding{
            padding:0!important;
        }
        .NoMargin-Desktop{
            @include media(">xs") {
                margin:0!important;
            } 
        }
        
        .PosRel{
            position:relative;
        } 

        $i : 0;
        @for $i from 1 through 20 {
            $j : $i / 10;
          .lh-#{$i} {
            line-height:$j;
          }
        }

    // Styles

        .fs-italic{
            font-style:italic;
        }

        // Transforms

        .ft-uppercase{
            text-transform:uppercase;
        }
        .ft-lowercase{
            text-transform:lowercase;
        }
        .ft-capitalize{
            text-transform:capitalize;
        }
        .ft-normal{
            text-transform:none;
        }


    // SVG Global rules

    svg{
        *{
            fill:currentColor;
        }
    }

    // Main wrap

    header{

        .BG{
            z-index:-2!important;
        }

        &:before{
            position:absolute;
            content:"";
            top:0;
            left:0;
            width:40vw;
            height:100%; 
            z-index:-1;
            background-image: linear-gradient(90deg, #000000 0%, rgba(0,0,0,0.00) 79%); 
        }
        &:after{
            position:absolute;
            content:"";
            top:0;
            right:0;
            width:40vw;
            height:100%; 
            z-index:-1;
            background-image: linear-gradient(-90deg, #000000 0%, rgba(0,0,0,0.00) 79%); 
        }

        .Logo{
            width:170px;
            svg{
                max-width:100%;
                height:auto;
            }
        } // Logo

        nav{
            justify-content:space-between;
            padding-top:20px;
            .SubNav{
                li{
                    &:not(:first-child){
                        margin-left:1.5em;
                    }
                }
            }
            .MainNav{
                border:1px solid $gray2;
                position:absolute;
                bottom:0;
                left:50%;
                transform:translate(-50%);
                display:flex; 
                line-height:1.4;
                align-items:stretch;
                max-width:750px;
                transition:all .15s;
                &:hover{
                    //border:1px solid transparent;
                }
                li{
                    flex-grow:1;
                    display:flex;
                    flex-direction:column;
                    align-items:center;
                    transition:all .15s;
                    position:relative;
                    
                    &:before{
                        pointer-events:none;
                        transition:all .15s;
                        position:absolute;
                        content:"";
                        top:-1px;
                        left:-1px;
                        width:calc(100% + 2px);
                        height:calc(100% + 2px);  
                        box-shadow: inset 0px 0px 0px 0px $blueLight;
                    }
                    &:hover{
                        color:$blueLight;
                        &:before{
                            box-shadow: inset 0px 0px 0px 5px $blueLight;
                        }
                    }
                    &:not(:last-child){
                        border-right:1px solid $gray2;
                    }
                } // li
                a{
                    vertical-align:middle;
                    display:inline-flex;
                    justify-content:center;
                    flex-direction:column;
                    padding:3em 0;
                    width:100%;
                    flex-grow:1;
                } // a
            } // MainNav
        } // Nav

        h1{
            letter-spacing:0.05em;
            line-height:1;
            strong{
                opacity:0.8;
            }
        } // h1
    
    } // header

    body:not(.Navigation_Open){
        header{
            &.BeingScrolled{
               
            }
        }
    }



    // Quote

    .Rounded_Quote{
        .Round{
            width:375px;
            height:375px;
            border-radius:100%;
            padding:40px;
            background-color:rgba(0,0,0,0.86);
        }
    } // Rounded Quote

    footer{ 

        .Btn.fc-white{
            transition:all .15s;
           &:hover{
             background:$white!important;
             border-color:$white!important;
             color:$blue!important;
           }
        }

        .Socials{
            li{
                margin:0 0.15em;
                &:hover{
                    a{
                        color:$blueLight;
                    }
                }
            }
        }

        .Sign{
            &:hover{
               *{
                 color:$white;
               }
            }
            .Icon{
                transform:translateY(-3px);
            }
        }

    
    } // footer

    
    //

    body{

    }
    

    // END GLOBAL STYLES






    
